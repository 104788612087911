import React from 'react'
import { withRouter } from 'react-router-dom'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { Tag,Popconfirm,Form, Input, Button, Select, message, Switch, Upload, Icon, Checkbox, Row, Col, Modal, Tooltip ,Radio,InputNumber} from 'antd';
import Table from "./table"
import {
    getGoodsDetail,
    getCategory,
    getGoodType,
    getGoodsSpecification,
    getGoodsAttr,
    putGoods,
    getGoodsAnnexTypeList,
    getTagList, 
} from "../../action/goodAction";
import { getEditorContent } from "../../action/AdAction"
import { getPartner, getLangList, getPartnerMemberLevel } from "../../action/authAction";
import intl from 'react-intl-universal';
import { getLangType } from "../../util/function";
import VideoImg from "../../img/iconVideo.png"
import silverImg from "../../img/member/silver.png"
import goldImg from "../../img/member/gold.png"
import diamondImg from "../../img/member/diamond.png"
const { TextArea } = Input;
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { CheckableTag } = Tag; 
//const tagsFromServer = ['iphone14', 'iphone15', 'iphone16', 'iphone16 plus'];

function beforeUpload(file) {
    if (file.status === "removed") {
        return true
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}


//单个图片上传的方法
function getBase64Img(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

 


class EditGoods extends React.Component {
    state = {
        GoodsData: "",
        CategoryData: "",
        GoodsSpecificationData: "",
        state: 1,
        imageUrl: "",
        imageUrlPiece:"", //新增
        goodImage: "",
        goodPieceImage:"", //新增
        loading: false,
        skuLoading: false,
        GoodsType: "",
        CategoryID: "",
        Category: "",
        recommend: 0,
        GoodsSpecificationItem: "",
        GoodsArr: "",
        editorState: "",
        dataSource: [],
        temp: "",
        ChooleSpecification: false,
        ChooleGoodsAnnexType: false,
        specification_id: "",
        spuSkuData: "",
        upstate: "",
        disable_language: true,
        is_gst: 0,
        weight:"",
        AnnexTypeData: "",
        ChooseAnnexTypeData: '',
        isModalVisible: false,
        ChooseDefaultData: [],
        defaultPrice: "",
        editingKey: false,
        is_open_goods_annex: false, 
        is_partner_gst: false ,
        is_open_gst:  false,
        is_weight:  false, 
        is_sku: false,
        is_open_recommend: false,
        is_disable_language: false, 
        consumptionData: [
            {
                value: 0,
                label: "到店",
                disabled: false
            },
            {
                value: 1,
                label: "配送"
            },

        ],
        consumption_type:[],//消费类型

        AdImageUrl: "",
        AdImage: "",
        AdVideo: "",
        AdVideoUrl: "",

        isMemberPrice:false,
        isMemberPriceVisible:false,
        partnerLevelData:[
             {'id':0,name:'请选择等级'}
            //  {'id':1,name:'普通会员'},
            // {'id':2,name:'白银会员'},
            // {'id':3,name:'黄金会员'},
            // {'id':4,name:'钻石会员'}, 
        ],
        member_price_info:[
            // {
            //     'member_level_name':'gold',
            //     'partner_member_level_id':"100",
            //     'member_price_type':"fixed",
            //     'member_price_on_fixed':"12",
            //     'member_price_on_percentage':"0.1", 
            //     'member_price':"10092", 
            // },

            // {
            //     'member_level_name':'silver',
            //     'partner_member_level_id':"101",
            //     'member_price_type':"percentage",
            //     'member_price_on_fixed':"21",
            //     'member_price_on_percentage':"0.2", 
            //     'member_price':"10092", 
            // },

            // {
            //     'partner_member_name':'diamond',
            //     'partner_member_level_id':"102",
            //     'member_price_type':"percentage",
            //     'member_price_on_fixed':"21",
            //     'member_price_on_percentage':"0.3", 
            //     'member_price':"10092", 
            // }, 
        ],//会员价格

        member_level_name:"",//会员name
        partner_member_level_id:"",//会员id 
        member_price_sort:0,//排序
        member_price_type:"fixed",//折扣类型
        member_price_on_fixed:"",//直减
        member_price_on_percentage:"",//百分比   
        member_price:"",//会员价格 


        blindstore_spu_rare_type:1,//盲盒商品稀有类型
        blindstore_spu_type:1,//盲盒商品类型
        blindstore_yuanbao_exchange:0,//兑换元宝商品所需要的数量
        is_blindstore_spu:false,//是否参与盲盒活动
        is_strict:false,//是否参与严选
        is_blindstore_piece:false,//是否拆成碎片
        isTagVisible:false,//tag库 
        blindstore_piece_type_num:5,//碎片种类数量
        selectedTags: [],


        tags: [],
        inputVisible: false,
        inputValue: '',



    };


    //tag start
      handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
      };

      showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
      };

      handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
      };

      handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
          tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
          tags,
          inputVisible: false,
          inputValue: '',
        });
      };

      saveInputRef = input => (this.input = input);


    //tag end




    handleChangeTag(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags: nextSelectedTags });
    }




    handleDelete = data => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== data.key) });
    } 


    async handleDeleteMemberPrice(index) {  

        // var arr = [{id: 1, name: '张三'}, {id: 2, name: '李四'}, {id: 3, name: '王五'}];
        // var idToDelete = 2; // 要删除的对象的 id 
        // arr = arr.filter(function(obj) {
        //   return obj.id !== idToDelete;
        // });
        // console.log(arr); // [{id: 1, name: '张三'}, {id: 3, name: '王五'}] 
        var member_price_info_del = this.state.member_price_info;
        var indexToDelete = index;   
        member_price_info_del.splice(indexToDelete, 1);   
        this.setState({ 
            member_price_info: member_price_info_del, 
        }) 

    } 



    async componentDidMount() {
        const id = this.props.location.pathname.substring(10)

        var  isMemberPrice = false;
        let GoodsData = await getGoodsDetail(id)
        let CategoryData = await getCategory()
        let GoodsTypeData = await getGoodType()
        let GoodsSpecificationData = await getGoodsSpecification()
        let GoodsArrData = await getGoodsAttr()
        let CategoryID = GoodsData.data.category_id
        let CategoryDataList = CategoryData.data.data
        let spuSku = GoodsData.data.spuSku && GoodsData.data.spuSku.data

        var memberPriceInfo =   GoodsData.data.spuMemberPrice.data 
        var memberPriceInfoNew = [];
        var memberPriceInfoUse = [];
        for (let i in memberPriceInfo) {  

            memberPriceInfoNew  = {'member_level_name':memberPriceInfo[i].member_level_name,
                                    'partner_member_level_id':memberPriceInfo[i].partner_member_level_id,
                                    'member_price_type':memberPriceInfo[i].member_price_type,
                                    'member_price_on_fixed':memberPriceInfo[i].member_price_on_fixed,
                                    'member_price_on_percentage':memberPriceInfo[i].member_price_on_percentage,
                                    'member_price':memberPriceInfo[i].member_price,
                                    'member_price_sort':memberPriceInfo[i].member_price_sort,

                                     };
            memberPriceInfoUse.push(memberPriceInfoNew) 
     
        }   
        

 
        //对会员价格进行排序  
        memberPriceInfoUse.sort(function (a, b) {
            return (a.member_price_sort - b.member_price_sort)
        });


 
 

        if(memberPriceInfo.length > 0){
            isMemberPrice = true;
        }else{
            isMemberPrice = false;
        }


        let AnnexTypeData = await getGoodsAnnexTypeList()
        let res = await getPartner()
        let data = res.data
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }



        let consumptionData = [...this.state.consumptionData]


        let tagList = await getTagList();
        let tagsFromServerList = [];
        let tagListData = tagList.data.data; 
        for (let i in tagListData) { 
            tagsFromServerList.push(tagListData[i]['name']) 
        }

        console.log('tagsFromServerList',tagsFromServerList);






        this.ChangeEditorData(GoodsData)
        for (let i in CategoryDataList) {
            if (CategoryDataList[i].id === CategoryID) {
                this.setState({
                    Category: CategoryDataList[i].id
                })
            }
        }

        let spuSkuIds = []
        var tempid = []; //一个新的临时数组
        var temp = []; //一个新的临时数组
        if (spuSku.length > 0) {
            this.setState({ ChooleSpecification: true })
            for (let i in spuSku) {
                let Item = spuSku[i].goods_specification_item_datas
                for (let x in Item) {
                    spuSkuIds.push(Item[x])
                }
            }
            for (let i in spuSkuIds) {
                if (tempid.indexOf(spuSkuIds[i].id) === -1) {
                    tempid.push(spuSkuIds[i].id)
                    temp.push(spuSkuIds[i]);
                }
            }
        }


        let AnnexTypeDataList = AnnexTypeData.data.data
        let spuGoodsAnnexTypeList = GoodsData.data.spuGoodsAnnexType.data
        let defaultGoodsAnnexType = []
        for (let i in AnnexTypeDataList) {
            for (let j in spuGoodsAnnexTypeList) {
                if (AnnexTypeDataList[i].id === spuGoodsAnnexTypeList[j].goods_annex_type_id) {
                    AnnexTypeDataList[i].defaultName = AnnexTypeDataList[i].name
                    defaultGoodsAnnexType.push(AnnexTypeDataList[i])
                }
            }
        }

        let fileList = [];
        let imgdata = GoodsData?.data?.spuImage?.data
        for (let i in imgdata) {
            fileList.push({
                uid: imgdata[i].id,
                name: imgdata[i].id,
                status: 'done',
                url: imgdata[i].image,
                sort: imgdata[i].sort === 0 ? "0" : imgdata[i].sort
            });
        }


        //碎片商品编辑
        let fileListPiece = [];
        let imgdataPiece = GoodsData?.data?.spuPieceImage?.data
        for (let i in imgdataPiece) {
            fileListPiece.push({
                uid: imgdataPiece[i].id,
                name: imgdataPiece[i].id,
                status: 'done',
                url: imgdataPiece[i].image,
                sort: imgdataPiece[i].sort === 0 ? "0" : imgdataPiece[i].sort
            });
        }


        //处理空tag内容
        let newtags1 = GoodsData.data.tags? GoodsData.data.tags.split("|"):[];
        let newtags = newtags1.filter(Boolean);
        
        console.log('tags2',newtags);




        this.setState({
            tagsFromServer:tagsFromServerList,
            consumptionData: consumptionData,
            CategoryData: CategoryDataList,
            GoodsTypeData: GoodsTypeData.data.data,
            GoodsSpecificationData: GoodsSpecificationData.data.data,
            GoodsArrData: GoodsArrData.data.data,
            GoodsData: GoodsData.data,
            member_price_info :memberPriceInfoUse,//价格信息
            isMemberPrice:isMemberPrice,//是否有折扣价格
            CategoryID: CategoryID,
            goodImage: fileList,
            goodPieceImage:fileListPiece,//碎片商品图片
            editorState: BraftEditor.createEditorState(GoodsData.data.detail),
            temp,
            spuSkuData: spuSku,
            upstate: GoodsData.data.state,
            disable_language: GoodsData.data.disable_language === 0,
            is_gst: GoodsData.data.is_gst ,
            blindstore_spu_rare_type: GoodsData.data.blindstore_spu_rare_type ,
            blindstore_spu_type: GoodsData.data.blindstore_spu_type , 
            is_blindstore_piece: GoodsData.data.is_blindstore_piece?true:false , 
            blindstore_piece_type_num: GoodsData.data.blindstore_piece_type_num , 
            weight :GoodsData.data.weight, 
            recommend: GoodsData.data.recommend,

            //商家已经选择的tag
            tags: newtags,
 
            AnnexTypeData: AnnexTypeDataList,
            ChooseAnnexTypeData: defaultGoodsAnnexType,
            ChooleGoodsAnnexType: defaultGoodsAnnexType.length > 0 ? true : false,


            //这里可能有bug GoodsData.data 还是 data 待明确
            is_partner_gst: data.is_partner_gst == 1 ? true : false,
            is_open_gst: data.is_open_gst == 1 ? true : false,
            is_weight: data.is_weight == 1 ? true : false,
             
            is_blindstore_spu: GoodsData.data.is_blindstore_spu == 1 ? true : false,
            is_strict: GoodsData.data.is_strict == 1 ? true : false,

             
            is_open_goods_annex: data.is_open_goods_annex == 1 ? true : false,
            is_sku: data.is_sku == 1 ? true : false,
            is_open_recommend: data.is_open_recommend == 1 ? true : false,
            is_disable_language: languageList.length === 1 ? true : false,

            consumption_type: GoodsData.data.consumption_type.toString() && (GoodsData.data.consumption_type !== 2 ? [GoodsData.data.consumption_type] : [0, 1]),

            AdImageUrl:  GoodsData.data.video_poster,
            AdVideoUrl:  GoodsData.data.video,


        }, () => this.HandleChooseDefault())


      


        //获取等级信息
        this.GetPartnerData();

 

        window.addEventListener(
            "popstate",
            function (e) {
                window.location.reload(); //跳转后执行的方法
                // alert('已监听到你从上个页面返回！');
            },
            false
        );
    }


    async GetPartnerData() {
            let resLevel = await getPartnerMemberLevel()
            let partnerLevelData = resLevel.data.data 
            this.setState({
                partnerLevelData: partnerLevelData, 
             })

     }


    componentWillUnmount() {
        this.setState = () => false;
    }


    ChangeEditorData = (data) => {
        getEditorContent(data.data.detail).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }



     async handleDeleteMemberPrice(index) {  

        // var arr = [{id: 1, name: '张三'}, {id: 2, name: '李四'}, {id: 3, name: '王五'}];
        // var idToDelete = 2; // 要删除的对象的 id 
        // arr = arr.filter(function(obj) {
        //   return obj.id !== idToDelete;
        // });
        // console.log(arr); // [{id: 1, name: '张三'}, {id: 3, name: '王五'}] 
        var member_price_info_del = this.state.member_price_info;
        var indexToDelete = index;   
        member_price_info_del.splice(indexToDelete, 1);   
        this.setState({ 
            member_price_info: member_price_info_del, 
        }) 

    } 


    async handleEditMemberPrice(index) {  
 
        var member_price_info = this.state.member_price_info; 
        member_price_info.map((item, key) => { 
            if(key == index){ 
                this.setState({ 
                    
                    partner_member_level_id:item.partner_member_level_id,
                    member_price_sort:item.member_price_sort,
                    member_price:item.member_price,
                    member_price_type:item.member_price_type,
                    member_price_on_fixed:item.member_price_on_fixed,
                    member_price_on_percentage:item.member_price_on_percentage,  
                    isMemberPriceVisible: true, 
                })

                console.log("member_price_on_fixed",this.state.member_price_on_fixed);
                console.log("member_price_on_percentage",this.state.member_price_on_percentage);
            }
             
        }) 
     } 
 




    HandleGetTableData = (data, ids, item, editingKey) => {
        if (editingKey) {
            this.setState({
                editingKey: true
            })
        } else {
            this.setState({
                editingKey: false
            })
        }
        var temp = []; //一个新的临时数组
        for (let i in ids) {
            if (temp.indexOf(ids[i]) === -1) {
                temp.push(ids[i]);
            }
        }
        this.setState({
            dataSource: data,
            specification_id: temp
        })
        for (let k in data) {
            if (parseInt(data[k].isDefault) === 1) {
                this.setState({ defaultPrice: data[k].price })
            }
        }
        if (item && parseInt(item.isDefault) === 1) {
            this.setState({
                defaultPrice: item.price
            })
            this.props.form.setFieldsValue({ price: item.price })
        }
    }

    checkPsd(rule, value, callback) {
        let abstract = this.props.form.getFieldValue('abstract');
        let len = 0;
        for (let i = 0; i < abstract.length; i++) {
            if (abstract.charCodeAt(i) > 127 || abstract.charCodeAt(i) == 94) {
                len += 2;
            } else {
                len++;
            }
        }
        if (len > 80) {
            callback(new Error(intl.get("goods.list.name.horder.max")));
        } else {
            callback();
        }
    }



    handleChangeAd = info => {


        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64Img(info.file, (AdImageUrl, img) =>
                    this.setState({
                        AdImageUrl,
                        AdImage: img
                    }),
                );
            }
        }
    };

    handleVideoChange = async (info) => {
        let isLt10M = info.file.size / 1024 / 1024 < 5
        if (!isLt10M) {
            message.error("Upload failed: video file exceeds 5 MB.Please shorten the video to 5-10 seconds and upload again.");
            return false;
        }
        let fileListData = info.file
        let videoNum = fileListData.type.indexOf("video")
        if (videoNum > -1) {
            this.setState({
                AdVideo: fileListData
            })
        } else {
            message.error("Please Select Video!")
        }
    }







    handleSubmit = (e) => {

        e.preventDefault();
        const { isMemberPrice, member_price_info, consumption_type, specification_id, dataSource, ChooseAnnexTypeData, ChooseDefaultData } = this.state
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.category_id = this.state.CategoryID
            values.recommend = this.state.recommend
            values.state = this.state.upstate.toString()
            //values.blindstore_piece_type_num = this.state.blindstore_piece_type_num.toString()
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.is_gst = this.state.is_gst  
            values.goods_specification_ids = specification_id.toString()
            values.detail = this.state.editorState.toHTML()

            values.tags = this.state.tags.length > 0 ? this.state.tags.join("|"):"|"

            values.is_blindstore_spu = this.state.is_blindstore_spu ? "1" : "0"
            values.is_strict = this.state.is_strict ? "1" : "0"
            values.is_blindstore_piece = this.state.is_blindstore_piece ? "1" : "0"
            values.consumption_type = consumption_type.length > 1 ? "2" : consumption_type 


            console.log("this.state.tags",this.state.tags);

            if(isMemberPrice){
                values["member_price"] = JSON.stringify(member_price_info)  
            }else{
                values["member_price"] = []  
            }
             
            let SubData = []
            if (dataSource.length > 0) {
                for (let k in dataSource) {
                    if (values.rrp && parseInt(values.rrp) < parseInt(dataSource[k].price)) {
                        message.error(intl.get("nav.goodsManagement.rrp_cannot_price"))
                        return false
                    }
                    let sku = "skus[" + dataSource[k].Id + "]"
                    SubData[sku + "[id]"] = dataSource[k].spu_id
                    SubData[sku + "[price]"] = dataSource[k].price.toString()
                    SubData[sku + "[stock]"] = dataSource[k].stock.toString()
                    SubData[sku + "[is_default]"] = dataSource[k].isDefault
                }
            } else {
                values.delete_sku = 1
            }

            let goods_annex_types = []
            for (let i in ChooseAnnexTypeData) {
                let goods_annex_type_item_ids = []
                if (ChooseAnnexTypeData[i].goodsAnnexTypeItem.data) {
                    let goodsAnnexTypeItem = ChooseAnnexTypeData[i].goodsAnnexTypeItem.data
                    for (let k in goodsAnnexTypeItem) {
                        goods_annex_type_item_ids.push(goodsAnnexTypeItem[k].id)
                    }
                }
                goods_annex_types[i] = { "goods_annex_type_id": ChooseAnnexTypeData[i].id, "goods_annex_type_item_ids": goods_annex_type_item_ids }
            }
            if (ChooseDefaultData && ChooseDefaultData.length > 0) {
                values["goods_annex_types"] = JSON.stringify(goods_annex_types)
            } else {
                values.delete_goods_annex_types = 1
            }


            Object.assign(values, SubData)
            if (this.state.editingKey) {
                message.error(intl.get("nav.goodsManagement.save_sku_first"))
                return false
            }

            if (!err) {
                if (values.rrp) {
                    if (parseInt(values.rrp) < parseInt(values.price)) {
                        message.error(intl.get("nav.goodsManagement.rrp_cannot_price"))
                        return false
                    }
                }
                this.SubmitData(values)
            }
        });
    };

    SubmitData(data) {
        
        const { imageUrlPiece,    imageUrl, AdImage, AdVideo,  } = this.state

        let goodImage = this.state.goodImage
        let goodPieceImage = this.state. goodPieceImage
        let formData = new FormData();
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }

        // formData.append("image_file", this.state.goodImage)
        const id = this.props.location.pathname.substring(10)


        //视频封面和视频

        AdImage && formData.append("video_poster", AdImage)
        AdVideo && formData.append("video", AdVideo)

        if (this.state.isMemberPrice && this.state.member_price_info.length == 0 ) { 
            message.error("请至少设置一个会员价格")
            return
        }

        
        let imgposition = [];
        if (goodImage && goodImage.length > 0) {
            for (let i in goodImage) {
                imgposition.push(i);
                formData.append('image_files[' + i + ']', goodImage[i].originFileObj);
            }
        }


         //碎片商品图片 
        if (goodPieceImage && goodPieceImage.length > 0) {
            for (let i in goodPieceImage) {
                imgposition.push(i);
                formData.append('image_piece_files[' + i + ']', goodPieceImage[i].originFileObj);
            }
        }





        if (imgposition.length > 0) {
            formData.append('img_data', imgposition.length > 0 ? imgposition.toString() : "-1");
        }

        let price = this.props.form.getFieldValue("price")
        let { defaultPrice } = this.state
        if (parseFloat(defaultPrice)) {
            if (parseFloat(defaultPrice) === parseFloat(price)) {
                putGoods(formData, id).then(res => {
                    // this.props.history.push("/goodsList")
                })
            } else {
                message.error(intl.get("goods.list.price.error"))
            }
        } else {
            putGoods(formData, id).then(res => {
                // this.props.history.push("/goodsList")
            })
        }


    }


    //检查固定费用
    get_member_price(value,type){ 

        
        if(type == 'fixed'){


            //计算服务费合计 
            let off_price = value ;  //减的价格 
            if(parseFloat(off_price) > 0 ){
                let price = this.state.price ;



                let member_price =  price - off_price;

                if(member_price < 0){ 
                    message.error("折扣后金额最少为$1")
                    this.setState({
                        member_price: 0,  
                    })
                }else{
                    this.setState({
                        member_price: member_price,  
                    })
                } 
            }

 

        }


        if(type == 'rate'){

            if(value == 0){
                message.error("折扣比例不能为0")
            }

            //计算服务费合计
            let price = this.state.price; 
            let off_price = this.accMul(price, value) / 100;  //减的价格    
            if(parseFloat(off_price) > 0 ){
                let price = this.state.price ;
                 
                let member_price =  price - off_price;

                if(member_price < 0){

                    message.error("折扣后金额最少为1")
                    this.setState({
                        member_price: 0,  
                    })
                }else{
                    this.setState({
                        member_price: member_price,  
                    })
                } 
            }

        }
 

    } 

    //固定折扣价格检查(暂时没用到)
    checkPriceFixed(rule, value, callback) {
 
        let price = this.state.price; 
        if (price - value < 1) {
            callback(new Error("折扣后金额最少为$1") );
        } else { 
            callback();
        }
    }

    //折扣百分比 价格检查
    checkPriceRate(rule, value, callback) {
        let price = this.state.price; 
        if (price) { 
            if (this.accMul(price, 100-value) / 100 < 1) {
                callback(new Error("折扣后金额最少为$1"));
            } else { 
                callback();
            }
        }

    }





    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }








    HandleChooseCategory = e => {
        this.setState({
            CategoryID: e
        });
    };
    handleChooseGoodsType = e => {
        this.setState({
            GoodsType: e
        });
    };


    handleCheckState = (checked) => {
        if (checked === 0) {
            this.setState({ upstate: 1 })
        } else {
            this.setState({ upstate: 0 })
        }
    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    goodImage: info.fileList
                })
            }
        }
    };
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            loading: true,
            imageUrl: file.url || file.preview
        })
    };




    //碎片商品图片上传 
    handleUploadPiece = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            let IsOk = beforeUpload(info.file);
            if (IsOk) {
                this.setState({
                    goodPieceImage: info.fileList
                })
            }
        }
    };


   handlePreviewPiece = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            loading: true,
            imageUrlPiece: file.url || file.preview
        })
    };







    HandleChooleSpecification = (e) => {
        if (!e.target.checked) {
            this.setState({
                dataSource: []
            })
        }
        this.setState({ ChooleSpecification: e.target.checked })
    }


    handleChooseGoodsArr = (e) => {
        this.setState({ GoodsArr: e })
    }


    handleChange = (editorState) => {
        this.setState({ editorState })
    }

    handleCancel = () => {
        this.setState({
            loading: false
        });
    };

    recommendHandle = (checked) => {
        if (checked) {
            this.setState({ recommend: 1 })
        } else {
            this.setState({ recommend: 0 })
        }
    }

    unique = (arr) => {
        //Set数据结构，它类似于数组，其成员的值都是唯一的
        return Array.from(new Set(arr)); // 利用Array.from将Set结构转换成数组
    }
    HandleDeleteAnnexTypeItem = (item) => {
        let { ChooseAnnexTypeData } = this.state
        for (let i in ChooseAnnexTypeData) {
            if (ChooseAnnexTypeData[i].id === item.id) {
                ChooseAnnexTypeData.splice(i, 1)
            }
        }
        this.setState({ ChooseAnnexTypeData }, () => this.HandleChooseDefault())
    }
    HandleChooseDefault = () => {
        let ChooseDefaultData = []
        let { ChooseAnnexTypeData, AnnexTypeData } = this.state
        for (let i in AnnexTypeData) {
            for (let k in ChooseAnnexTypeData) {
                if (ChooseAnnexTypeData[k].id === AnnexTypeData[i].id) {
                    ChooseDefaultData.push(AnnexTypeData[i])
                }
            }
        }
        this.setState({ ChooseDefaultData: [...ChooseDefaultData] })
    }



    handleChangeSelect = (value,e) => { 


        var label = e.props.children; 


         let member_price_sort = 0;

        switch(label){
            case "silver" : member_price_sort = 1; break;
            case "gold" : member_price_sort = 2; break;
            case "diamond" : member_price_sort = 3; break;
            default: member_price_sort = 0; break;

        }


        this.setState({
            partner_member_level_id: value,
            member_level_name:label,
            member_price_sort:member_price_sort,
            }) 
            console.log(`Selected Value: ${value}`); // 输出选中的 value 值
            console.log(`Selected Value: ${label}`); // 输出选中的 value 值 
     
      }


    handleReset=()=>{
      this.props.form.resetFields("partner_member_level_id");
      this.props.form.resetFields("member_price_on_fixed");
      this.props.form.resetFields("member_price_on_percentage");

    }

    render() {
        const { tagsFromServer , inputVisible,inputValue,  selectedTags,  isTagVisible,  blindstore_spu_type , blindstore_spu_rare_type, is_strict,  is_blindstore_spu, is_blindstore_piece, blindstore_piece_type_num,tags,   isMemberPriceVisible,isMemberPrice,member_level_name, member_price, member_price_type, member_price_on_fixed, member_price_on_percentage, member_price_info, partnerLevelData, AdImageUrl, AdVideoUrl,  AdVideo, consumption_type, consumptionData, is_partner_gst,is_open_gst,is_weight,weight, CategoryData, GoodsTypeData, recommend, imageUrl, imageUrlPiece,GoodsData, editorState, Category, GoodsSpecificationData, temp, ChooleSpecification, ChooleGoodsAnnexType, spuSkuData, loading, upstate, disable_language, is_gst, AnnexTypeData, isModalVisible, ChooseAnnexTypeData, goodImage,  goodPieceImage,ChooseDefaultData, is_open_goods_annex, is_sku, is_open_recommend, is_disable_language } = this.state
        const { name, price, abstract, rrp, is_not_update } = GoodsData
        const { getFieldDecorator } = this.props.form;

        const { Option } = Select;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const uploadAdButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );


        let AnnexTypeNum = 0
        for (let i in ChooseAnnexTypeData) {
            let goodsAnnexTypeItemData = ChooseAnnexTypeData[i].goodsAnnexTypeItem && ChooseAnnexTypeData[i].goodsAnnexTypeItem.data
            for (let k in goodsAnnexTypeItemData) {
                if (goodsAnnexTypeItemData[k].is_default === 1) {
                    AnnexTypeNum = AnnexTypeNum + parseFloat(goodsAnnexTypeItemData[k].price)
                }
            }
        }

        if (CategoryData && GoodsTypeData && GoodsData && editorState) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1200px" }}>
                    <Form.Item
                        label="ID"
                    >
                        <Input disabled value={GoodsData.id} />
                    </Form.Item>
                    <Form.Item
                        label="PDID"
                    >
                        <Input disabled value={GoodsData.bdid} />
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.name')}
                    >
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: intl.get('goods.list.name.horder'),
                                whitespace: true,
                            }, { max: 64, message: intl.get('goods.list.name.horder.max') }],
                            initialValue: name
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>
                            {intl.get('goods.list.img')}
                            <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}
                    >
                        {getFieldDecorator('goods_image', {
                            rules: [{ required: true }],
                            initialValue: "qwe"
                        })(
                            <div>
                                <Upload
                                    accept=".jpg,.png,.jpeg"
                                    listType="picture-card"
                                    fileList={goodImage}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleUpload}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                >
                                    {goodImage.length >= 10 ? null : (
                                        <div>
                                            <div className="ant-upload-text">Add Picture</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal
                                    visible={loading}
                                    // title={previewTitle}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                                </Modal>
                            </div>
                        )}
                    </Form.Item>




                     {/*<Form.Item
                            label={<span>
                                商品视频
                                <Tooltip title={intl.get("setup.form.recruitmentVideo.tips")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>}
                        >
                            {!!AdVideoUrl && <div style={{ width:"200px"}}>
                                <video src={AdVideoUrl} style={{ width: "100%" }} controls />
                                <Button onClick={() => this.setState({ AdVideoUrl: "" })}>{intl.get("home.table.operation.btn.delete")}</Button>
                            </div>}
                            {
                                !AdVideoUrl && <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                    onChange={this.handleVideoChange}
                                >

                                    {!!AdVideo ? <img src={VideoImg} alt="avatar" style={{ width: '100%' }} /> : uploadAdButton}
                                </Upload>
                            }


                        </Form.Item>



                        <Form.Item
                            label={<span>
                                视频封面
                                <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>}
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={this.handleChangeAd}
                            >
                                {!!AdImageUrl ? <img src={AdImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadAdButton}
                            </Upload>
                        </Form.Item>

                        */}



                    <Form.Item
                        label={intl.get('goods.list.abstract')}
                    >
                        {getFieldDecorator('abstract', {
                            rules: [{ required: false }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                            initialValue: abstract
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>
                            {intl.get('goods.list.rrp_price')}
                            <Tooltip title={intl.get("goods.list.rrp_price_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}
                    >
                        {getFieldDecorator('rrp', {
                            initialValue: rrp
                        })(<Input prefix={currency_symbol} />)}
                    </Form.Item>

                    <Form.Item
                        label={intl.get('goods.list.price')}
                    >
                        {getFieldDecorator('price', {
                            rules: [{
                                required: true,
                                message: intl.get('goods.list.price.horder'),
                                // whitespace: true,
                            }],
                            initialValue: price
                        })(<Input prefix={currency_symbol} 

                        onKeyUp={() =>  this.setState({ member_price_info: [] }) }

                        />)}
                    </Form.Item>


                     { 
                        <Form.Item label={<span>参与盲盒活动</span>}> 
                         {getFieldDecorator('is_blindstore_spu', {
                                rules: [{ required: false, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                                initialValue: [0]
                            })(
                                <Checkbox checked={is_blindstore_spu}  onChange={(e) => {
                                    this.setState({ is_blindstore_spu: e.target.checked })
                                }} />
                            )} 
                        </Form.Item>
                    }


                    {  is_blindstore_spu && 
                        <Form.Item
                            label="稀有类型"
                        >
                            {getFieldDecorator('blindstore_spu_rare_type', {
                                rules: [{ required: false }],
                                initialValue:blindstore_spu_rare_type
                            })(<Radio.Group>
                                <Radio  value={1}>N</Radio>
                                <Radio  value={2}>R</Radio>
                                <Radio value={3}>S</Radio>
                                <Radio  value={4}>SS</Radio>
                                <Radio  value={5}>SSS</Radio>
                            </Radio.Group>)}
                        </Form.Item>  
                    }


                    {  is_blindstore_spu &&
                        <Form.Item
                            label="盲盒商品类型"
                        >
                            {getFieldDecorator('blindstore_spu_type', {
                                rules: [{ required: false }],
                                initialValue:blindstore_spu_type
                            })(<Radio.Group>
                                <Radio  value={1}>盲盒商品</Radio>  
                            </Radio.Group>)}
                        </Form.Item>  
                    }


                    {  is_blindstore_spu &&
                        <Form.Item label={<span>是否拆分成碎片</span>}> 
                         {getFieldDecorator('is_blindstore_piece', {
                                rules: [{ required: false, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                                initialValue: is_blindstore_piece
                            })(
                                <Checkbox checked={is_blindstore_piece} onChange={(e) => {
                                    this.setState({ is_blindstore_piece: e.target.checked })
                                }} />
                            )} 
                        </Form.Item>
                    }

                    {  is_blindstore_spu && is_blindstore_piece && 
                       <Form.Item
                            label='碎片种类数量'
                        >
                            {getFieldDecorator('blindstore_piece_type_num', { 
                                initialValue: blindstore_piece_type_num?blindstore_piece_type_num:5
                            })(<Input prefix='' />)}
                            {/* rules: [{ required: false, message: "", whitespace: true }],*/}
                        </Form.Item>  
                    }


                    { is_blindstore_spu && is_blindstore_piece && 
                    <Form.Item
                        label={<span>
                            商品碎片图片
                            <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}
                    >
                        {getFieldDecorator('goods_piece_image', {
                            rules: [{ required: true }],
                            initialValue: "qwe"
                        })(
                            <div>
                                <Upload
                                    accept=".jpg,.png,.jpeg"
                                    listType="picture-card"
                                    fileList={goodPieceImage}
                                    onPreview={this.handlePreviewPiece}
                                    onChange={this.handleUploadPiece}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                >
                                    {goodPieceImage.length >= 10 ? null : (
                                        <div>
                                            {/* <PlusOutlined /> */}
                                            <div className="ant-upload-text">Add Picture</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal
                                    visible={loading}
                                    // title={previewTitle}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={imageUrlPiece} />
                                </Modal>
                            </div>
                        )}
                    </Form.Item>

                    }




                  { 
                        <Form.Item label={<span>参与严选</span>}> 
                         {getFieldDecorator('is_strict', {
                                rules: [{ required: false, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                                initialValue: [0]
                            })(
                                <Checkbox checked={is_strict}  onChange={(e) => {
                                    this.setState({ is_strict: e.target.checked })
                                }} />
                            )} 
                        </Form.Item>
                    }






                <Form.Item
                    label="商品标签"
                >
                    {/*getFieldDecorator('tags', {
                        rules: [{
                            required: false, 
                            whitespace: true,
                        }, { max: 500, message: intl.get('goods.list.name.horder.max') }],

                    })(<Input />)*/

                    }

                        <div  style={{ display: "flex", justifyContent: "space-between" }}>  
                            <div> 
                                  {tags.map((tag, index) => {
                                      const isLongTag = tag.length > 20;
                                      const tagElem = (
                                        <Tag key={tag} closable={index>=0} onClose={() => this.handleClose(tag)}>
                                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                        </Tag>
                                      );
                                      return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                          {tagElem}
                                        </Tooltip>
                                      ) : (
                                        tagElem
                                      );
                                    })}
                                    {inputVisible && (
                                      <Input
                                        ref={this.saveInputRef}
                                        type="text"
                                        size="small"
                                        style={{ width: 78 }}
                                        value={inputValue}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputConfirm}
                                        onPressEnter={this.handleInputConfirm}
                                      />
                                    )}
                                    {!inputVisible && (
                                      <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                                        <Icon type="plus" /> New Tag
                                      </Tag>
                                    )} 
                           </div>

                            <div> 
                                <Button type="primary"
                                    style={{ height: "25px" }}
                                    onClick={() => {  
                                        this.setState({
                                             isTagVisible: true,  
                                        })

                                    } }
                                >
                                    添加TAG
                                </Button>
                            </div>  

                        </div> 
                </Form.Item>

                 {/* tag   start  */}

                <Modal title="TAG库"
                        visible={isTagVisible}
                        centered={true} 
                        onCancel={() => {
                        this.setState({
                                 isTagVisible: false,  
                            })

                        this.handleReset();
                        
                        }}
                        onOk={() => { 
                             this.setState({ 
                                 tags :this.state.selectedTags,
                                 isTagVisible: false, 
                            })   

                        }} 
                        width={800}
                    > 
                     <div>
                        <span style={{ marginRight: 8 }}>请选择TAG:</span>
                        {tagsFromServer.map(tag => (
                          <CheckableTag
                            key={tag}
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={checked => this.handleChangeTag(tag, checked)}
                          >
                            {tag}
                          </CheckableTag>
                        ))}
                      </div>

                    </Modal>

                    {/* tag  end */}



                     {/* 
                       <Form.Item
                            label='商品库存'
                        >
                            {getFieldDecorator('weight', {
                                rules: [{ required: true, message: intl.get('goods.list.price.horder'), whitespace: true }],
                            })(<Input prefix='' />)}
                        </Form.Item> 
                    */}


                    { 
                        <Form.Item label={<span>是否在EM APP显示 </span>}>
                            <Switch checked={recommend === 1} onChange={this.recommendHandle} />
                        </Form.Item>
                    }










                    {
                    
                    <Form.Item label={<span>设置会员价格</span>}>
                        <Checkbox checked={isMemberPrice}  onChange={(e) => {
                            this.setState({ isMemberPrice: e.target.checked })
                        }} />
                    </Form.Item>
                }


                 {
                     isMemberPrice && <Form.Item  label="会员价格" > 
                        <div style={{  display: "flex", justifyContent:"space-between", alignItems: "flex-start" }}> 
                        <div> 
                             {  member_price_info?.map((items, index) => {
                                    return   <div style={{display:"flex",justifyContent: "space-between",alignItems: "center"  }}>

                                         
                                            {/* 
                                                 <Form.Item
                                                    label={items.member_level_name}
                                                    style={{  display:"flex"}} 
                                                >
                                        {getFieldDecorator('member_level_price[]', {
                                                    rules: [{ required: false, message: intl.get('goods.list.price.horder'), whitespace: true }],
                                                    initialValue: items.member_price
                                                })(<Input prefix={currency_symbol} disabled 
                                                    onChange={(e) => {
                                                        e.persist()
                                                        this.setState({
                                                            //price: e.target.value
                                                        })
                                                }} />)}

                                                 </Form.Item>  
                                            */}
 
                                           <div>


                                              <span>



                                               { items.member_level_name == 'silver' ?  <img src={silverImg} alt="avatar" style={{ width: '20px',paddingRight:"5px" }} /> : "" }
                                               { items.member_level_name == 'gold' ?  <img src={goldImg} alt="avatar" style={{ width: '20px',paddingRight:"5px"  }} /> : "" }
                                               { items.member_level_name == 'diamond' ?  <img src={diamondImg} alt="avatar" style={{ width: '20px',paddingRight:"5px"  }} /> : "" }

 

                                               ${items.member_price}</span>

                                                    <span style ={{ padding:"0 5px"}} >(

                                                {
                                                    items.member_price_type === "fixed" ? "-" + currency_symbol +  items.member_price_on_fixed :
                                                        items.member_price_on_percentage + "% off" +  ` / -${currency_symbol}` + 

                                                        (parseFloat(this.accMul(parseFloat(price), parseFloat(items.member_price_on_percentage) || 1)) / 100).toFixed(2)   + ""

                                                }
                                                

                                                )</span>

                                           </div>

                                       


                                         





                                        <div> 

                                            {/*
                                                <Button style={{margin:"0 15px"}} 
                                                onClick={() => 
                                                     this.handleEditMemberPrice(index)  
                                                }>
                                                编辑

                                                </Button>

                                            */}



                                            


                                            <Popconfirm title="确认删除？"  
                                                onConfirm={() =>  
                                                    this.handleDeleteMemberPrice(index) 
                                                 }> 
                                                    <Button  style={{marginLeft:"15px"}} >删除</Button> 
                                            </Popconfirm>



                                             
                                        </div>



                                        </div>
                                       
                                    })
                                   
                            }
 
                  
                        </div>



                         <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>  </span> 
                                <Button type="primary"
                                    style={{ height: "25px" }}
                                    onClick={() => 

                                    {

                                       if(this.state.price == ""){
                                            message.error('请输入销售价格后再添加会员价格');
                                            return;
                                        }


                                    this.setState({ 
                                        isMemberPriceVisible: true,
                                        price:price,
                                        partner_member_level_id:"",
                                        member_price:"",
                                        member_price_type:"fixed",
                                        member_price_on_fixed:"",
                                        member_price_sort:0,
                                        member_price_on_percentage:"", 
                                        })

                                    }


                                    }
                                >
                                    添加会员价格
                                </Button>
                        </div>   
                        </div> 
                        </Form.Item> 

                    }





                     {/* member price  start  //////////////////////////////////////////*/}

                     <Modal title="会员价格设置"
                        visible={isMemberPriceVisible}
                        centered={true} 
                        onCancel={() => {
                        this.setState({
                                 isMemberPriceVisible: false, 
                                //refund_message: ""
                            })

                            this.handleReset();

                            
                        }}
                        onOk={() => { 



                            //判断会员价格是否重复添加
                            //判断会员价格大小顺序
                            
                             var isAlready = true;//已经添加
                             var isErroPrice = false;//价格错误
                             var member_price =  this.state.member_price;
                             var member_level_name =   this.state.member_level_name;
                             var silver_price = "";
                             var gold_price = "";
                             var diamond_price = "";


                             if(!this.state.partner_member_level_id){
                                message.error("请选择会员等级")
                                return;
                             }


                            if(this.state.member_price_type == 'fixed' && this.state.member_price_on_fixed == 0){
                                message.error("折扣金额不能为0")
                                return;
                            }
                                

                            if(this.state.member_price_type == 'percentage' && this.state.member_price_on_percentage == 0){
                                message.error("折扣金额不能为0")
                                return;
                            }




                             this.state.member_price_info.forEach((item, index) => {
                                    if(item.partner_member_level_id == this.state.partner_member_level_id){  
                                        isAlready = false;
                                        //return false;
                                    }
 
                                    //获取白银价格
                                    if(item.member_level_name == 'silver'){
                                         silver_price = item.member_price;
                                    }


                                    //获取黄金价格
                                    if(item.member_level_name == 'gold'){
                                         gold_price = item.member_price;
                                    }


                                    //获取钻石价格 
                                    if(item.member_level_name == 'diamond'){
                                         diamond_price = item.member_price;
                                    }


                                }); 



                             //判断商家添加的会员价格 
                            if(member_level_name == "diamond"){ 
                                if( (gold_price && member_price < gold_price)  &&  (silver_price && member_price < silver_price) ){
                                     isErroPrice = true; 
                                }else if( gold_price && member_price < gold_price  && !silver_price ){
                                     isErroPrice = true; 
                                }else if( silver_price && member_price < silver_price   && !gold_price  ){
                                     isErroPrice = true; 
                                }else if(  !silver_price && !gold_price   ){
                                    isErroPrice = true;
                                }
                            }


                            if(member_level_name == "gold"){  

                                console.log("member_price",member_price);
                                console.log("member_price",silver_price);
                                console.log("member_price",gold_price);
                                console.log("member_price",diamond_price);
                                
                                if( (diamond_price && member_price > diamond_price)  &&  (silver_price && member_price < silver_price) ){
                                     isErroPrice = true; 
                                }else if( diamond_price && member_price > diamond_price && !silver_price ){ 
                                     isErroPrice = true; 
                                }else if(  silver_price && member_price < silver_price && !diamond_price ){ 
                                     isErroPrice = true; 
                                }else if(  !silver_price && !diamond_price   ){
                                    isErroPrice = true;
                                } 
                            }


                            if(member_level_name == "silver"){

                                if( (diamond_price && member_price > diamond_price) &&  (gold_price && member_price > gold_price) ){
                                     isErroPrice = true; 
                                }else if(  diamond_price && member_price > diamond_price   && !gold_price ){
                                     isErroPrice = true; 
                                }else if( gold_price && member_price > gold_price  && !diamond_price ){
                                     isErroPrice = true; 
                                }else if(  !gold_price && !diamond_price   ){
                                    isErroPrice = true;
                                }
                            }




                             if(!isAlready){
                                message.error('该会员等级已经添加了价格'); 
                                return;
                             }


                             if(!isErroPrice){
                                message.error('会员价格设置不正确'); 
                                return;
                             }


                             //添加会员价格



 
                            let member_price_add = { 
                                    'member_level_name': this.state.member_level_name ,
                                    'partner_member_level_id':this.state.partner_member_level_id, //注意这里
                                    'member_price_sort':this.state.member_price_sort,
                                    'member_price_type':this.state.member_price_type,
                                    'member_price_on_fixed':this.state.member_price_on_fixed,
                                    'member_price_on_percentage':this.state.member_price_on_percentage, 
                                    'member_price':this.state.member_price, 
                            };
                            member_price_info.push(member_price_add);


                            // sort by value
                            member_price_info.sort(function (a, b) {
                                return (a.member_price_sort - b.member_price_sort)
                            });




                            this.setState({
                                 isMemberPriceVisible: false, 
                                 member_price_info : member_price_info,
                            }) 


                            this.handleReset();



                            //  putPartnerStatementPay(data.id, 
                            //          { 
                            //             settle_status: settle_status , 
                            //             pay_status :   pay_status ,
                            //             statement_note: statement_note
                            //          } ).then(res => {  
                                     
                            //        setTimeout(() => {
                            //          this.setState({
                            //             statementvisible: false
                            //         }, () =>  
                            //             this.getDetail() 
                            //         ) 
                            //      }, 3000) 
                            //   }) .catch(error => {
                            //     this.setState({ 
                            //         statementvisible: false
                            //     })
                            // }) 

                        }} 
                        width={800}
                    > 


                        <Form.Item
                            label="商品价格"
                           
                        >
                            {getFieldDecorator('price', {
                                rules: [{ required: false, message: intl.get('goods.list.price.horder'), whitespace: true }],
                                initialValue: price
                            })(<Input prefix={currency_symbol} disabled   />)}
                        </Form.Item>




                          <Form.Item
                            label={<span>请选择等级</span>}
                        >
                            {getFieldDecorator('partner_member_level_id', {
                                rules: [{ required: false, message: '请选择等级!' }],
                                initialValue: "请选择等级"
                            })(
                                <Select  onChange={(value,e) => this.handleChangeSelect(value,e)} >
                                    {partnerLevelData && partnerLevelData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>

                           <Form.Item
                                label={intl.get('nav.goodsManagement.promotionalgoods.coupon_discount_type')}
                            >
                                <Radio.Group 
                                    onChange={(e) => { 
                                       this.setState({
                                            member_price_type: e.target.value
                                        })
                                    }}
                                    value={member_price_type}
                                    > 
                                    <Radio value={"fixed"}>{intl.get("nav.goodsManagement.promotionalgoods.quota_discount")}</Radio>
                                    <Radio value={"percentage"}>{intl.get("nav.goodsManagement.promotionalgoods.percent_discount")}</Radio> 
                                </Radio.Group>
                            </Form.Item>

             
                                
                            {
                                member_price_type == 'fixed' && <Form.Item
                                label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}
                            >
                                {getFieldDecorator('member_price_on_fixed', {
                                    rules: [{ required: false, message: "" } , { validator: (rule, value, callback) => { this.checkPriceFixed(rule, value, callback) } } ],
                                    initialValue: "0"
                                })(<InputNumber
                                    value={member_price_on_fixed}
                                    onKeyUp={() => this.get_member_price(this.props.form.getFieldValue('member_price_on_fixed'),'fixed')}
                                    onChange={(e) => {
                                        //e.persist()
                                        this.setState({
                                            member_price_on_fixed: e
                                        })
                                    }} 

                                    formatter={value => `${currency_symbol}${value}`}
                                    parser={value => value.replace(currency_symbol, '')}
                                    style={{ width: "200px" }} />)}
                            </Form.Item>  

                            }

                            {
                                member_price_type == 'percentage' && <Form.Item
                                label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage')}
                            >
                                {getFieldDecorator('member_price_on_percentage', {
                                    rules: [{ required: false, pattern: new RegExp(/^[1-99]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.enter_integer") }, { validator: (rule, value, callback) => { this.checkPriceRate(rule, value, callback) } }],
                                    initialValue: "0"
                                })(<InputNumber 
                                    value={member_price_on_percentage}
                                    onKeyUp={() => this.get_member_price(this.props.form.getFieldValue('member_price_on_percentage'),'rate')}
                                    onChange={(e) => {
                                        //e.persist()
                                        this.setState({
                                            member_price_on_percentage: e
                                        })
                                    }} 

                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')} min={0} max={99} style={{ width: "200px" }}
                                />)}
                            </Form.Item> 

                            }



                              

                             <Form.Item
                                label="折扣金额"
                               
                            >

                            { (member_price_on_percentage > 0 || member_price_on_fixed > 0 ) ?

                                 member_price_type === "fixed" ? "-" + currency_symbol +   member_price_on_fixed :
                                     member_price_on_percentage + "% off" +  ` (-${currency_symbol}` + 

                                    (parseFloat(this.accMul(parseFloat(price), parseFloat(member_price_on_percentage) || 1)) / 100).toFixed(2)   + ")"

                                : "-"
                            }


                            </Form.Item> 
                             


                             

                            <Form.Item
                                label="折扣后价格"
                               
                            >
                               
                               {/*

                                {getFieldDecorator('member_price', {
                                    rules: [{ required: false, message: intl.get('goods.list.price.horder'), whitespace: true }],
                                    initialValue: ""
                                })(<Input prefix={currency_symbol}   
                                    onChange={(e) => {
                                        //e.persist()
                                        this.setState({
                                            member_price: e.target.value
                                        })
                                    }} 
                                />)}

                               */} 

                               {  (member_price_on_percentage > 0 || member_price_on_fixed > 0 ) ? 
                                <span>
                                {currency_symbol}{member_price?member_price:price}
                                </span> : "-"
                            }

                            </Form.Item> 

                             


                    </Modal>

                    {/* member price  end //////////////////////////////////////////*/}







                     { (is_partner_gst &&  is_open_gst ) ?  
                        <Form.Item
                            label="GST"
                        >
                            {getFieldDecorator('is_gst', {
                                rules: [{ required: true }],
                                initialValue:is_gst
                            })(<Radio.Group>
                                <Radio  value={0}>无</Radio>
                                <Radio value={1}>含GST</Radio>
                                <Radio  value={2}>不含 GST</Radio>
                            </Radio.Group>)}
                        </Form.Item> 

                        :

                        <Form.Item
                            label="GST"
                        >
                            {getFieldDecorator('is_gst', {
                                rules: [{ required: false }],
                                initialValue:is_gst
                            })(<Radio.Group>
                                <Radio  value={0}>无</Radio> 
                            </Radio.Group>)}
                        </Form.Item> 

                    }




                    {/*is_weight && 
                       <Form.Item
                            label='商品重量'
                        >
                            {getFieldDecorator('weight', {
                                rules: [{ required: false, message: intl.get('goods.list.price.horder'), whitespace: true }],
                                 initialValue: weight
                            })(<Input prefix='kg  '    />)}
                        </Form.Item> 
                    */}
                    

                    <Form.Item
                        label="消费类型"
                    >
                        {getFieldDecorator('consumption_type', {
                            rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                            initialValue: consumption_type
                        })(
                            <Checkbox.Group
                                options={consumptionData}
                                onChange={(e) => {
                                    this.setState({
                                        consumption_type: e.length > 1 ? 2 : e[0]
                                    })
                                }} />
                        )}
                    </Form.Item>






                    {
                        Category ? <Form.Item
                            label={intl.get('goods.list.type')}
                        >
                            <Select defaultValue={Category} style={{ width: "100%" }} onChange={this.HandleChooseCategory}>
                                {
                                    CategoryData.length !== 0 && CategoryData.map(item => {
                                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item> :
                            <Form.Item
                                label={intl.get('goods.list.type')}
                            >
                                <Select style={{ width: "100%" }} onChange={this.HandleChooseCategory}>
                                    {
                                        CategoryData.length !== 0 && CategoryData.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    }
                    <Form.Item
                        label={intl.get('goods.list.sort')}
                    >
                        {getFieldDecorator('sort', {
                            rules: [{ required: false }],
                            initialValue: GoodsData.sort
                        })(<Input />)}
                    </Form.Item>


                    {/*
        
                    <Form.Item
                        label={intl.get('package.service.goods_count')}
                    >
                        {getFieldDecorator('stock', {
                            initialValue: GoodsData.stock
                        })(<Input />)}
                    </Form.Item>

                    */}
                    
                    


                    <Form.Item
                        label={intl.get('goods.list.sale_quantity')}
                    >
                        <Input value={GoodsData.sale_quantity} disabled />
                    </Form.Item>
                    <Form.Item
                        label={intl.get('goods.list.unit_number')}
                    >
                        {getFieldDecorator('unit_number', {
                            rules: [{ required: false }],
                            initialValue: GoodsData.unit_number
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label={<span>{intl.get('goods.list.isOk')}</span>}>
                        <Switch checked={upstate === 1} onChange={() => this.handleCheckState(upstate)} />
                    </Form.Item>
                    {
                        !is_disable_language &&
                        <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                            <Switch checked={disable_language}
                                onChange={() => {
                                    this.setState({
                                        disable_language: !disable_language
                                    })
                                }} />
                        </Form.Item>
                    }
                    {
                        is_open_recommend &&
                        <Form.Item label={<span>{intl.get('goods.list.selected')}</span>}>
                            <Switch checked={recommend === 1} onChange={this.recommendHandle} />
                        </Form.Item>
                    }

                    {/* 
                    <Form.Item label={<span>{intl.get('goods.list.is_gst')}</span>}>
                        <Switch checked={is_gst}
                            onChange={() => {
                                this.setState({
                                    is_gst: !is_gst
                                })
                            }} />
                    </Form.Item> */}

                    {
                        is_sku &&
                        <Form.Item label={<span>{intl.get('goods.list.isspeci')}</span>}>
                            <Checkbox checked={ChooleSpecification} onChange={this.HandleChooleSpecification} />
                        </Form.Item>
                    }

                    {
                        ChooleSpecification ?
                            <Table
                                price={price}
                                rrp={this.props.form.getFieldValue('rrp')}
                                GoodsSpecificationData={GoodsSpecificationData}
                                getData={this.HandleGetTableData}
                                temp={temp}
                                spuSkuData={spuSkuData}
                            // defaultPrice={price}
                            /> : <div />
                    }
                    {
                        is_open_goods_annex &&
                        <Form.Item label={<span>{intl.get('nav.goodsManagement.isitional')}</span>}>
                            <Checkbox checked={ChooleGoodsAnnexType} onChange={(e) => {
                                this.setState({ ChooleGoodsAnnexType: e.target.checked })
                            }} />
                        </Form.Item>
                    }


                    {
                        ChooleGoodsAnnexType && <Form.Item
                            label={intl.get('goods.list.goodsAnnexType')}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>{currency_symbol}{AnnexTypeNum}</span>
                                <Button type="primary"
                                    style={{ height: "25px" }}
                                    onClick={() => this.setState({ isModalVisible: true })}
                                >
                                    {intl.get('nav.goodsManagement.itemadditional.add')}
                                </Button>
                            </div>
                            {
                                ChooseAnnexTypeData ? ChooseAnnexTypeData.map((items, index) => {
                                    return <div key={index}>
                                        <div style={{ width: "300px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontWeight: "600" }}>{items.name}</span>
                                            <Button type="link"
                                                // style={{ height: "25px" }}
                                                onClick={() => this.HandleDeleteAnnexTypeItem(items)}
                                            >
                                                {intl.get('home.table.operation.btn.delete')}
                                            </Button>
                                        </div>
                                        <div style={{ marginTop: "-5px" }}>
                                            <Row>
                                                {
                                                    items.goodsAnnexTypeItem.data.map((item, key) => {
                                                        return <Col span={24} key={key}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {
                                                                    item.is_default === 1 ?
                                                                        <span style={{ width: "8px", height: "8px", borderRadius: "5px", backgroundColor: "#000", marginRight: "5px" }} /> :
                                                                        <span style={{ width: "8px", height: "8px", borderRadius: "5px", backgroundColor: "#fff", marginRight: "5px" }} />
                                                                }

                                                                <span style={{ lineHeight: "25px" }}>{item.name + "   " + currency_symbol + item.price}</span>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                }) : <div style={{ textAlign: "center", height: "50px" }}>{intl.get("nav.goodsManagement.noAdditionalGoods")}</div>
                            }
                        </Form.Item>
                    }


                    <Form.Item
                        label={intl.get('goods.list.info')}
                    >
                        <div style={{
                            border: "1px solid #dedede",
                        }}>
                            <BraftEditor
                                value={editorState}
                                language={Number(getLangType()) == 1 ? "zh" : "en"}
                                onChange={this.handleChange} />
                        </div>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                        {
                            !!!is_not_update && !(is_not_update === 1) &&
                            <Button type="primary" htmlType="submit">
                                {intl.get('cashwithdrawal.form.submit')}
                            </Button>
                        }

                    </Form.Item>
                    <Modal title={intl.get("nav.goodsManagement.additional")}
                        visible={isModalVisible}
                        centered={true}
                        onCancel={() => this.setState({ isModalVisible: false })}
                        footer={null}
                        width={800}
                    >
                        <Checkbox.Group
                            value={ChooseDefaultData}
                            onChange={(value) => {
                                this.setState({
                                    ChooseAnnexTypeData: value
                                }, () => this.HandleChooseDefault())
                            }}>
                            <Row>
                                {AnnexTypeData.map((item, index) => {
                                    return <Col span={8} key={index} style={{ marginBottom: "10px" }}>
                                        <Checkbox value={item}>{item.name}</Checkbox>
                                    </Col>
                                })}
                            </Row>
                        </Checkbox.Group>
                    </Modal>
                </Form>
            );
        } else {
            return (<div />)
        }



    }
}

export default withRouter(Form.create()(EditGoods))
